import React, { useState } from "react"
import { RichText } from "prismic-reactjs"
import Image from "./image"
import { css } from "@emotion/core"
import { colors } from "../styles/styles"
import SwiperCore, { Navigation, Controller } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import linkResolver from "../utils/linkResolver"

SwiperCore.use([Navigation, Controller])

function Feedback({ feedback }) {
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)
  return (
    <div css={feedBackCss}>
      <section className="section is-medium is-theme2 feedback-section">
        <div className="container">
          <h3 className="title is-3 has-text-centered has-text-weight-semibold pb-4 title-feed">
            {RichText.asText(feedback.title)}
          </h3>
          <h6 className="subtitle is-5 has-text-centered has-text-weight-light">
            {RichText.asText(feedback.subtitle)}
          </h6>
          <div className="columns is-centered">
            <div className="column is-9">
              <nav className="py-5">
                <div className="has-text-centered">
                  <Swiper
                    centeredSlides={true}
                    loop={true}
                    loopedSlides={1}
                    onSwiper={setFirstSwiper}
                    slideToClickedSlide={true}
                    controller={{ control: secondSwiper }}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                      },
                    }}
                    className="swiper-feedback-controller"
                  >
                    {feedback.feedback.map((fb, i) => {
                      return (
                        <SwiperSlide key={i}>
                          {fb.feedback_image && (
                            <Image
                              sharp={fb.feedback_imageSharp}
                              image={fb.feedback_image}
                            />
                          )}
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </div>
              </nav>
            </div>
          </div>
          <div className="columns is-centered is-multiline">
            <Swiper
              onSwiper={setSecondSwiper}
              controller={{ control: firstSwiper }}
              loop={true}
              loopedSlides={1}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              className="swiper-feedback"
            >
              {feedback.feedback.map((fb, i) => {
                return (
                  <SwiperSlide key={i}>
                    <div className="column box is-7-desktop is-10-touch has-text-centered has-text-weight-medium px-5 py-5 speech-bubble">
                      <h6 className="title is-4 has-text-weight-medium">
                        {RichText.asText(fb.feedback_title)}
                      </h6>
                      <div className="is-size-5 has-text-weight-light speech-bubble-text">
                        {fb.feedback_content &&
                          RichText.render(fb.feedback_content, linkResolver)}
                      </div>
                      <div className="dash-spaced mt-6">&#8203;</div>
                      <div className="has-text-weight-semibold is-size-5">
                        {fb.feedback_name}
                      </div>
                      <div className="has-text-weight-light is-size-6">
                        {fb.feedback_rank}
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })}
              <div
                role="button"
                name="Previous slide"
                aria-label="Previous slide"
                className="swiper-button-prev"
              ></div>
              <div
                role="button"
                name="Next slide"
                aria-label="Next slide"
                className="swiper-button-next"
              ></div>
            </Swiper>
          </div>
        </div>
      </section>
    </div> 
  )
}

export default Feedback

const feedBackCss = css`
  .feedback-section {
    margin-top: -5rem;
  }
  .box {
    border-radius: 12px;
    box-shadow: 0 19px 7px 0 #c5ccde;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-bottom-color: #fff;
      border-top: 0;
      margin-left: -20px;
      margin-top: -20px;
    }
  }

  .swiper-feedback-controller .gatsby-image-wrapper {
    display: flex;
    align-items: center;
  }
  .dash-spaced {
    background-image: linear-gradient(
      to right,
      ${colors.lightgray} 30%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: top;
    background-size: 10px 1px;
    background-repeat: repeat-x;
    width: 200px;
    margin: 0 auto;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    .gatsby-image-wrapper {
      height: 80px;
      width: 200px;
      img {
        max-height: 80px;
      }
    }
  }
.title-feed {
  margin-top: -9%
}
  .swiper-feedback {
    padding-top: 40px;
    padding-bottom: 40px;

    .speech-bubble-text {
      color: ${colors.blueLightSeven};
    }
  }

  @media (max-width: 767px) {
    .swiper-button-prev,
    .swiper-button-next {
      background-color: transparent;
    }
  }

  @media (min-width: 1024px) {
    .swiper-feedback .swiper-slide.swiper-slide-next {
      overflow: visible;
      right: 33%;
      opacity: 30%;
    }

    .swiper-feedback .swiper-slide.swiper-slide-prev {
      overflow: visible;
      left: 33%;
      opacity: 30%;
    }

    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
      left: 12%;
      right: auto;
    }
    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
      right: 12%;
      left: auto;
    }

  }
`
